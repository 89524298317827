import React from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';

import Grid from '@mui/material/Grid';

// eslint-disable import/no-cycle
import { menuPoints } from '../../App';
import MainContext from '../../MainContext';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        height: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        overflow: 'hidden',
    },
};

let lastRedirect = null;

export default function Account(props) {
    const context = React.useContext(MainContext);
    const navigate = useNavigate();

    if (context.user === false) {
        if (!lastRedirect || Date.now() - lastRedirect > 2000) {
            lastRedirect = Date.now();
            setTimeout(() => {
                context.setLoginRedirect(window.location.pathname + window.location.search + window.location.hash);
                navigate('/www/login');
            }, 100);
        }
        return null;
    }

    const routes = Object.keys(menuPoints).map(e => {
        let menuItem = menuPoints[e];
        if (menuItem.pro && context.theme === 'pro') {
            menuItem = menuItem.pro;
            e = menuItem.route;
        }

        const Component = menuItem.component;
        return (
            <Route
                path={`${e}`}
                key={e}
                element={<Component style={props.style} />}
            />
        );
    });
    return (
        <Grid
            container
            alignContent="center"
            style={styles.root}
        >
            <Grid
                item
                xs={12}
                style={styles.content}
            >
                <Routes>{routes}</Routes>
            </Grid>
        </Grid>
    );
}
