import React from 'react';
import copy from 'copy-to-clipboard';
import { jwtDecode } from 'jwt-decode';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { FileCopy, Close } from '@mui/icons-material';

import __ from '../../../utils/i18n';
import MainContext from '../../../MainContext';

export default function LicenseDialog(props) {
    const context = React.useContext(MainContext);

    let data;
    try {
        data = jwtDecode(props.text);
    } catch (e) {
        props.snackbar(`Cannot decode license key: ${e}`, 'error');
    }

    return (
        <Dialog
            open={!0}
            onClose={() => props.onClose()}
            aria-labelledby="license-dialog-title"
            aria-describedby="license-dialog-description"
        >
            <DialogTitle id="license-dialog-title">{props.name}</DialogTitle>
            <DialogContent>
                {data
                    ? Object.keys(data).map(key => (
                          <TextField
                              style={{ marginTop: 10 }}
                              key={key}
                              label={
                                  key === 'iat' ? __('Created') : key[0].toUpperCase() + key.substring(1).toLowerCase()
                              }
                              value={
                                  key === 'expires' || key === 'iat'
                                      ? new Date(data[key] * 1000).toLocaleString()
                                      : data[key]
                              }
                              variant="standard"
                              fullWidth
                              readOnly
                          />
                      ))
                    : null}
                <div
                    style={{
                        marginTop: 10,
                        fontSize: 12,
                    }}
                >
                    {__('License key')}
                </div>
                <div style={{ wordBreak: 'break-all' }}>{props.text}</div>
            </DialogContent>
            <DialogActions>
                <Button
                    startIcon={<FileCopy />}
                    variant="outlined"
                    onClick={() => {
                        copy(props.text);
                        context.snackbar('License was copied into the clipboard', 'success');
                    }}
                >
                    {__('Copy to clipboard')}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => props.onClose()}
                    color="primary"
                    autoFocus
                    startIcon={<Close />}
                >
                    {__('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
