import { CLOUD } from '../MainContext';
import prices from '../data/prices';
import { getLang, setLang } from '../utils/i18n';

const PORT = window.location.hostname.startsWith('iobroker.') ? window.location.port : 443;

export { PORT };

function _apiGetUrl(path) {
    return `https://iobroker.${CLOUD}:${PORT}/api/v1/${path}`;
}

function _apiLocalFetch(path, method, data, authNotRequired) {
    const options = {
        method: method || 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };

    if (!authNotRequired) {
        options.credentials = 'include';
    }

    if (method === 'POST' || method === 'DELETE' || method === 'PUT' || method === 'PATCH') {
        options.body = JSON.stringify(data);
    }

    try {
        return fetch(_apiGetUrl(path), options)
            .then(res => {
                if (res.status >= 400) {
                    const status = res.status;
                    return res.json().then(_data => {
                        _data = _data || {};
                        _data.statusCode = status;
                        return Promise.reject(_data);
                    });
                }
                return res.json();
            })
            .catch(e => {
                if (e?.error?.text) {
                    return Promise.reject(e.error.text);
                }
                if (e?.error) {
                    return Promise.reject(typeof e.error === 'object' ? JSON.stringify(e.error) : e.error);
                }
                if (e?.message) {
                    return Promise.reject(e.message);
                }
                return Promise.reject(typeof e === 'object' ? JSON.stringify(e) : e);
            });
    } catch (e) {
        if (e?.error?.text) {
            return Promise.reject(e.error.text);
        }
        if (e?.error) {
            return Promise.reject(typeof e.error === 'object' ? JSON.stringify(e.error) : e.error);
        }
        if (e?.message) {
            return Promise.reject(e.message);
        }
        return Promise.reject(typeof e === 'object' ? JSON.stringify(e) : e);
    }
}

let userProfilePromise;
export function apiGetUserProfile(update) {
    if (update || !userProfilePromise) {
        userProfilePromise = _apiLocalFetch('user').then(data => {
            data && data.lang && getLang() !== data.lang && setLang(data.lang);
            return data;
        });
    }

    return userProfilePromise;
}

export function apiCreateSubscription(data) {
    return _apiLocalFetch('subscriptions', 'POST', data);
}
export function apiCreateLicense(data) {
    return _apiLocalFetch('licenses', 'POST', data);
}

export function apiLogin(username, password, remember) {
    return _apiLocalFetch('login', 'POST', {
        username,
        password,
        remember: remember || false,
    });
}

export function apiLogout() {
    return _apiLocalFetch('logout', 'POST');
}
export function apiGetKey(/* appKeyId */) {
    return _apiLocalFetch('appkeys');
}
export function apiDeleteKey(appKeyId) {
    return _apiLocalFetch(`appkeys/${encodeURIComponent(appKeyId)}`, 'DELETE');
}
export function apiCreateKey(type) {
    return _apiLocalFetch(`appkeys?type=${type || 0}`, 'POST');
}
export function apiReadLicenses(type) {
    return _apiLocalFetch(`licenses?type=${type || 'sw'}`);
}
export function apiReadSubscriptions(type) {
    return _apiLocalFetch(`subscriptions?type=${type || 'all'}`);
}
export function apiRegister(email, password, privacyAck) {
    return _apiLocalFetch('user', 'POST', {
        email,
        password,
        privacyAck,
    });
}
export function apiUpdateUser(data) {
    return _apiLocalFetch('user', 'PUT', data);
}
export function apiChangeEmail(email, password, all) {
    return _apiLocalFetch('/email/requestChange', 'POST', {
        email,
        password,
        all,
    });
}
export function apiSendConfirmationEmail() {
    return _apiLocalFetch('password/confirm', 'POST');
}
export function apiChangePassword(oldPassword, newPassword) {
    return _apiLocalFetch('password/change', 'POST', { oldPassword, password: newPassword });
}
export function apiCheckResetPasswordCode(code) {
    return _apiLocalFetch(`password/reset?code=${code}&check=true`);
}
export function apiChangePasswordByCode(password, code) {
    return _apiLocalFetch('password/reset', 'POST', { password, code });
}
export function apiEmailConfirm(code) {
    return _apiLocalFetch(`password/confirm?code=${code}`);
}
export function apiRequestResetPasswordEmail(email) {
    return _apiLocalFetch('password/requestReset', 'POST', { email });
}
export function apiAckContract() {
    return apiUpdateUser({ contractAck: true });
}
export function apiDeleteUser() {
    return _apiLocalFetch('user', 'DELETE');
}
export function apiGetStatisticsCloud() {
    return _apiLocalFetch('cloud');
}
export function apiGetStatisticsIot() {
    return _apiLocalFetch('iot');
}
export function apiGetProducts() {
    return _apiLocalFetch('public/products', 'GET', null, true).then(data => {
        data?.forEach(product => {
            product.localData = prices.find(price => price.name === product.name);
        });

        return data;
    });
}

export function apiConvertLicense(id, uuid) {
    return _apiLocalFetch(`licenses/${id}`, 'POST', { uuid }, false);
}

export function apiGetInvoice(invoice) {
    return _apiLocalFetch(`invoices/${encodeURIComponent(invoice)}`);
}

export function apiGetInstanceConnected() {
    return fetch(`https://iobroker.${CLOUD}:${PORT}/checkConnection`)
        .then(res => res.json())
        .then(json => json && json.result)
        .catch(e => {
            if (e && e.error) {
                return Promise.reject(e.error);
            }
            if (e && e.message) {
                return Promise.reject(e.message);
            }
            if (e && e.error && e.error.text) {
                return Promise.reject(e.error.text);
            }

            return Promise.reject(typeof e === 'object' ? JSON.stringify(e) : e);
        });
}

let paypalPromise; // cache requests
export function apiGetPaypalAccount() {
    paypalPromise = paypalPromise || _apiLocalFetch('public/paypal', 'GET', null, true);

    return paypalPromise;
}

let countriesPromise; // cache requests
export function apiGetCountries() {
    countriesPromise = countriesPromise || _apiLocalFetch('public/countries', 'GET', null, true);

    return countriesPromise;
}

export function apiGetPaypalToken() {
    return _apiLocalFetch('paypalToken', 'POST');
}

export function apiGetAddress() {
    return _apiLocalFetch(`address`);

}

export async function apiGetUUIDs() {
    const result = await _apiLocalFetch(`deviceUUID`);

    return result?.UUIDs || [];
}
