import React from 'react';

import {
    Typography,
    Divider,
    Box,
    Button,
    Input,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';

import {
    FileCopy,
    Delete,
    Cancel,
} from '@mui/icons-material';

import { red } from '@mui/material/colors';
import copy from 'copy-to-clipboard';

import { apiDeleteKey, apiGetKey, apiCreateKey } from '../../../api';
import MainContext from '../../../MainContext';
import __ from '../../../utils/i18n';
import { MOBILE_WIDTH } from '../../../utils/consts';

const styles = {
    divider: {
        width: '100%',
        margin: '20px 0',
    },
    comments: {
        color: red[900],
        fontStyle: 'italic',
    },
    flexContainer: {
        display: 'flex',
        width: '100%',
    },
    keyInput: {
        flex: 1,
    },
    keyButton: {},
};

export default function RemoteAccessContents(props) {
    const context = React.useContext(MainContext);
    const [appKey, setAppKey] = React.useState(null); // null=requesting,'' = no key or error
    const [confirmDialog, setConfirmDialog] = React.useState(false);

    if (!context.user) {
        return null;
    }

    function readKey() {
        apiGetKey()
            .then(result => setAppKey(Array.isArray(result) && result[0] ? result[0].key : ''))
            .catch(error => {
                setAppKey('');
                context.snackbar(__('Cannot read keys %s', __(error)), 'error', true);
            });
    }

    if (appKey === null) {
        readKey();
    }

    const handleAppKeyDelete = () =>
        apiDeleteKey(appKey)
            .then(() => {
                readKey();
                context.snackbar('Key deleted', 'success');
            })
            .catch(error => context.snackbar(__('Key cannot be deleted %s', __(error)), 'error', true));

    const handleAppKeyGenerate = () =>
        apiCreateKey(0)
            .then(() => {
                readKey();
                context.snackbar('Key generated', 'success');
            })
            .catch(error => {
                context.snackbar(__('Key cannot be generated %s', __(error)), 'error', true);
            });

    const handleAppKeyCopy = () => {
        copy(appKey);
        context.snackbar('Key copied to clipboard', 'success');
    };

    const dialog = confirmDialog ? (
        <Dialog
            open={!0}
            onClose={() => setConfirmDialog(false)}
        >
            <DialogTitle>{__('Please confirm...')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {__(
                        'You will not able to access your system remotely without key. Do you really want to delete the APP Key?',
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setConfirmDialog(false);
                        handleAppKeyDelete();
                    }}
                    startIcon={<Delete />}
                >
                    {__('Delete')}
                </Button>
                <Button
                    variant="outlined"
                    autoFocus
                    onClick={() => setConfirmDialog(false)}
                    startIcon={<Cancel />}
                >
                    {__('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;

    return (
        <Box
            sx={theme => ({
                ...theme.container[props.style],
                padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
            })}
        >
            {dialog}
            <Typography paragraph>
                {__(
                    'To access your ioBroker instance remotely, you must create the app-key and copy it into the configuration dialog of iobroker.cloud adapter.',
                )}
            </Typography>
            {!appKey && appKey !== null ? (
                <Button
                    onClick={handleAppKeyGenerate}
                    variant="contained"
                    color="primary"
                >
                    {__('Generate APP-KEY')}
                </Button>
            ) : null}
            {appKey ? (
                <>
                    <Divider style={styles.divider} />
                    <Typography paragraph>{__('You have following APP-KEY:')}</Typography>
                    <div style={styles.flexContainer}>
                        <Input
                            style={styles.keyInput}
                            fullWidth
                            value={appKey}
                        />
                        <IconButton
                            style={styles.keyButton}
                            onClick={handleAppKeyCopy}
                            aria-label="copy"
                            edge="end"
                            size="small"
                        >
                            <FileCopy />
                        </IconButton>
                    </div>
                    <Divider style={styles.divider} />
                    <Typography paragraph>
                        {__('Copy it into the configuration dialog of iobroker.cloud adapter.')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setConfirmDialog(true)}
                        startIcon={<Delete />}
                    >
                        {__('Delete APP-KEY')}
                    </Button>
                </>
            ) : null}
        </Box>
    );
}
