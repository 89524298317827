function getProductImage(productName) {
    if (productName.startsWith('assistant.')) {
        return '/www/products/voice-assistant.svg';
    }
    if (productName.startsWith('remote.')) {
        return '/www/products/remote-access.svg';
    }
    if (productName.startsWith('iobroker.')) {
        return `/www/products/${(productName.split('_')[0]).split('.')[1]}.png`;
    }
    if (productName.startsWith('link.')) {
        return '/www/products/link.png';
    }

    return `/www/products/${productName.split('_')[0]}.png`;
}

export default getProductImage;
