import React from 'react';
import {
    InputLabel,
    Input,
    FormControl,
    FormHelperText,
    InputAdornment,
    IconButton,
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import __ from './i18n';

function getPasswordStatus(password) {
    password = (password || '').toString();

    const status = {
        empty: false,
        startsWithSpace: false,
        endsWithSpace: false,
        unexpectedChars: false,
        lowLength: false,
        noNumbers: false,
        noLowChars: false,
        noCapitals: false,
        foundHashOrQuestion: false,
    };

    if (!password) {
        status.empty = true;
    }
    if (password.startsWith(' ') || password.startsWith('\t')) {
        status.startsWithSpace = true;
    }
    if (password.endsWith(' ') || password.endsWith('\t')) {
        status.endsWithSpace = true;
    }
    if (password.includes('#') || password.includes('?')) {
        status.foundHashOrQuestion = true;
    }
    if (password.match(/[^-.,_+§$!=()[\]{}&%°;:a-zA-ZüäößÜÄÖа-яА-ЯЁё0-9#?]/)) {
        status.unexpectedChars = true;
    }
    if (password.length < 8) {
        status.lowLength = true;
    }
    if (!password.match(/\d/)) {
        status.noNumbers = true;
    }
    if (!password.match(/[a-züäößа-яё]/)) {
        status.noLowChars = true;
    }
    if (!password.match(/[A-ZÜÄÖА-ЯЁ]/)) {
        status.noCapitals = true;
    }

    return status;
}

const PASSWORD_TEXTS = {
    password_empty: 'Password is empty',
    password_startsWithSpace: 'Starts with space',
    password_endsWithSpace: 'Ends with space',
    password_unexpectedChars: 'Unsupported characters',
    password_lowLength: 'Minimal length is 8',
    password_noNumbers: 'No digits',
    password_noLowChars: 'No lower case',
    password_noCapitals: 'No upper case',
    password_foundHashOrQuestion: '# or ? not allowed',
};

function status2text(status) {
    return Object.keys(status)
        .filter(attr => status[attr])
        .map(attr => __(PASSWORD_TEXTS[`password_${attr}`]))
        .join(', ');
}

function isError(status) {
    if (!status) {
        return false;
    }
    return !!Object.keys(status)
        .find(attr => status[attr]);
}

function PasswordInput(props) {
    const [status, setStatus] = React.useState({
        empty: false,
        startsWithSpace: false,
        endsWithSpace: false,
        unexpectedChars: false,
        lowLength: false,
        noNumbers: false,
        noLowChars: false,
        noCapitals: false,
        foundHashOrQuestion: false,
    });
    const [showPassword, setShowPassword] = React.useState(false);

    return <FormControl style={props.style} variant="standard">
        {props.label ? <InputLabel htmlFor={props.id}>{__(props.label)}</InputLabel> : null}
        <Input
            ref={props.inputRef || undefined}
            id={props.id}
            error={props.check && isError(status)}
            aria-describedby={props.describedBy}
            type={showPassword ? 'text' : 'password'}
            placeholder={props.placeholder ? __(props.placeholder) : undefined}
            inputProps={{
                autoComplete: 'new-password',
                form: { autoComplete: 'off' },
            }}
            value={props.value}
            onChange={e => {
                let _status;
                if (props.check) {
                    _status = getPasswordStatus(e.target.value);
                    setStatus(_status);
                }
                props.onChange && props.onChange(e.target.value, isError(_status));
            }}
            onKeyDown={e => props.onKeyDown && props.onKeyDown(e)}
            onBlur={e => props.onBlur && props.onBlur(e)}
            onFocus={e => props.onFocus && props.onFocus(e)}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onMouseDown={event => event.preventDefault()}
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            }
        />
        {props.check ? <FormHelperText
            id="password-error-helper-text"
            style={{ color: '#f44336' }}
        >
            {status2text(status)}
        </FormHelperText> : null}
    </FormControl>;
}
export default PasswordInput;
