import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography, FormControl, InputLabel, Input, Button } from '@mui/material';
import { Check as IconCheck } from '@mui/icons-material';

import { apiChangePasswordByCode, apiCheckResetPasswordCode } from '../../api';
import getParam from '../../utils/getParam';
import MainContext from '../../MainContext';
import PasswordInput from '../../utils/PasswordInput';
import __, { setLang } from '../../utils/i18n';

const styles = {
    btn: {
        marginTop: 20,
    },
    row: {
        marginBottom: 20,
        width: '100%',
    },
};

export default function ChangePassword() {
    const navigate = useNavigate();
    const context = React.useContext(MainContext);

    const [password, setPassword] = React.useState('');
    const [focus, setFocus] = React.useState(false);
    const [isError, setError] = React.useState(false);
    const [visible, setVisible] = React.useState(false);

    const passwordConfirmRef = React.useRef(null);

    const handlePassword = (pass, isError) => {
        setPassword(pass);
        setError(isError);
    };

    const [confirmPassword, setConfirmPassword] = React.useState('');

    const handleConfirmPassword = event => setConfirmPassword(event.target.value);
    const code = getParam('code');
    const lang = getParam('lang');
    lang && setLang(lang);

    const [result, setResult] = React.useState(null);

    if (result === null) {
        setResult(false);
        apiCheckResetPasswordCode(code)
            .then((/* data */) => setResult(true))
            .catch((/* error */) => setResult(false));
    }

    if (result === null) {
        return null;
    }

    const handleSentPassword = (/* event */) => {
        if (password !== confirmPassword) {
            context.snackbar(__('Passwords are not same'), 'error');
            return;
        }
        apiChangePasswordByCode(password, code)
            .then(() => {
                context.setLoginRedirect('/www/account/profile');
                setTimeout(() => navigate('/www/login'), 4000);
            })
            .catch(error => context.snackbar(error, 'error'));
    };

    const handleFocus = element => setFocus(element);
    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            if (focus === 'password') {
                passwordConfirmRef.current.focus();
            } else if (focus === 'password_confirm') {
                password && password === confirmPassword && handleSentPassword();
            }
        }
    };

    return (
        <Grid
            container
            alignContent="center"
            alignItems="center"
            direction="column"
            style={{ textAlign: 'center' }}
        >
            <Typography
                paragraph
                component="div"
            >
                <h1>{__('Change password')}</h1>
            </Typography>
            {result ? (
                <>
                    <div style={styles.row}>
                        <PasswordInput
                            label={__('New password')}
                            onKeyDown={handleKeyDown}
                            onBlur={() => handleFocus(false)}
                            onFocus={() => handleFocus('password')}
                            onVisbilityChange={isVisible => setVisible(isVisible)}
                            check
                            style={{ width: '100%', maxWidth: 250 }}
                            id="newPassword"
                            value={password}
                            onChange={handlePassword}
                        />
                    </div>
                    {!visible ? (
                        <div style={styles.row}>
                            <FormControl
                                variant="standard"
                                style={{ width: '100%', maxWidth: 250 }}
                            >
                                <InputLabel htmlFor="repeatPassword">{__('Repeat password')}</InputLabel>
                                <Input
                                    inputRef={passwordConfirmRef}
                                    onKeyDown={handleKeyDown}
                                    onBlur={() => handleFocus(false)}
                                    onFocus={() => handleFocus('password_confirm')}
                                    id="repeatPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPassword}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                        form: { autoComplete: 'off' },
                                    }}
                                />
                            </FormControl>
                        </div>
                    ) : null}
                    <div style={styles.row}>
                        <Button
                            style={styles.btn}
                            type="submit"
                            variant="contained"
                            onClick={handleSentPassword}
                            disabled={!password || (!visible && password !== confirmPassword) || isError}
                            startIcon={<IconCheck />}
                        >
                            {__('Change password')}
                        </Button>
                    </div>
                </>
            ) : (
                __('Wrong restore code')
            )}
        </Grid>
    );
}
